import { Container } from "@mui/material";
import { ElementReference } from "../../api/types";
import Grid from "@mui/material/Grid2";

interface ElementDetailedViewProps {
    element: ElementReference;
    isOption?: boolean;
}

const GridView = ({ label, value, size }: { label: string, value: string, size: number }) => {
    return (
        <Grid size={size} sx={{
            overflow: "hidden",
        }}>
            <b>
                {label}:
            </b>
            {value}
        </Grid>
    );
}

export const ElementDetailedView = ({
    element,
    isOption,
}: ElementDetailedViewProps) => {
    if (!element.extension && element.taxonomyElement) {
        return <Container
            maxWidth={"lg"}
            sx={{
                display: "flex",
                wordWrap: "break-word",
            }}
        >

            <Grid container direction="column" wrap="nowrap">
                <Grid container wrap="nowrap">
                    <GridView label="Namespace Abbreviation" value={element?.taxonomyElement?.namespaceAbbreviation || ""} size={8} />
                    <GridView label="Name" value={element?.taxonomyElement?.name || ""} size={4} />
                    <GridView label="Description" value={element?.taxonomyElement?.description || ""} size={4} />
                </Grid>
                <Grid container wrap="nowrap">
                    <GridView label="Element Name" value={element?.name || ""} size={4} />
                </Grid>
            </Grid>
        </Container>;
    }

    return (
        <Container
            maxWidth={"lg"}
            sx={{
                display: "flex",
                wordWrap: "break-word",
            }}
        >
            <>
                <Grid container direction="column" wrap="nowrap">
                    <Grid container wrap="nowrap">
                        <GridView label="Invert Sign" value={element?.extension?.invertSign ? "True" : "False"} size={8} />
                        <GridView label="Is Credit" value={element?.extension?.isCredit ? "True" : "False"} size={4} />
                        <GridView label="Wider" value={element?.extension?.parentReference?.name || ""} size={4} />
                    </Grid>
                    <Grid container wrap="nowrap">
                        <GridView label="Company" value={element?.extension?.company?.name || ""} size={4} />
                        <GridView label="Taxonomies" value={element?.extension?.taxonomies?.join(",") || ""} size={4} />
                        <GridView label="Narrower" value={element?.extension?.labels?.map(
                            (childElementReference) => childElementReference?.role
                        ).join(", ") || ""} size={4} />
                    </Grid>
                </Grid>
            </>
        </Container >
    );
}