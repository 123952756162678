import {
  TableHead,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DataTableColumn } from "./dataTable";
import { Apifilters } from "../api/types";

export interface TableColumnHeader<T> {
  id: keyof T | string;
  label: string;
  width?: number;
  optionsHeaderText?: string;
  options?: string[];
  shouldBeFiltered?: boolean;
  inputType?: "text" | "select";
}

export interface TableHeaderProps<T> {
  column: DataTableColumn<T>;
  columnHeaders: TableColumnHeader<T>[];
  expandable?: boolean;
  nested?: boolean;
  filters: Apifilters[];
  setFilter?: (id: string, value: string) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,  // Smaller font size for table body cells
  },
}));

const TableHeaderLabel = ({ text, type }: { text: string, type: "text" | "select" | undefined }) => (
  <Typography fontSize="0.65rem" color="white" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {text}
    {type === "text" &&
      <FilterAltIcon fontSize="inherit" sx={{ ml: 0.5 }} />
    }
  </Typography>
);

const useInitialSelectedValues = (filters: Apifilters[]) => {
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});
  useEffect(() => {
    const initialValues = filters.reduce((acc, filter) => {
      if (filter.values?.length === 1) acc[filter.column] = filter.values[0];
      return acc;
    }, {} as { [key: string]: string });
    setSelectedValues((prev) => ({ ...initialValues, ...prev }));
  }, [filters]);
  return [selectedValues, setSelectedValues] as const;
};

const commonStyles = {
  height: '36px',
  color: "white",
  '& .MuiSelect-select, & .MuiOutlinedInput-root': {
    color: "white",
    height: '100%',
    padding: '0 10px',
    borderRadius: '4px',
    fontSize: '0.65rem',
  },
  '& .MuiSelect-icon, & .MuiOutlinedInput-notchedOutline': {
    color: 'white',
    borderColor: 'transparent',
  },
  '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#093a5e',
  },
};

export default function TableHeader<T>({
  columnHeaders,
  expandable,
  nested,
  setFilter,
  filters,
}: TableHeaderProps<T>) {
  const [selectedValues, setSelectedValues] = useInitialSelectedValues(filters);

  const handleSelectChange = (id: string, value: string) => {
    setSelectedValues((prev) => ({ ...prev, [id]: value }));
    setFilter?.(id, value);
  };

  return (
    <TableHead>
      <TableRow>
        {expandable && <StyledTableCell sx={{ width: 48 }} />}
        {columnHeaders.map((ch, index) => {
          const columnOptions = ch.options ?? filters.find(f => f.column === ch.id)?.values;
          const inputType = ch.inputType ?? "text";
          const isFiltered = setFilter && ch.shouldBeFiltered;

          return (
            <StyledTableCell key={`head_column_${index}`} width={ch.width} sx={{ color: 'white', width: ch.width }}>
              {isFiltered ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', width: ch.width }}>
                  <FormControl sx={{ minWidth: ch.width || 150, ...commonStyles, maxWidth: ch.width, width: ch.width }} size="small" variant="outlined">
                    <InputLabel sx={{ color: "white" }} shrink={!!selectedValues[ch.id as string]}>
                      <TableHeaderLabel text={ch.label ?? ch.optionsHeaderText ?? ''} type={ch.inputType} />
                    </InputLabel>
                    {inputType === "select" ? (
                      <Select
                        value={selectedValues[ch.id as string] || ''}
                        onChange={(e) => handleSelectChange(ch.id as string, e.target.value as string)}
                      >
                        {(!columnOptions || !columnOptions.includes("")) && (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                        {Array.isArray(columnOptions) && columnOptions.map((option, idx) => (
                          <MenuItem key={idx} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <TextField
                        variant="outlined"
                        size="small"
                        value={selectedValues[ch.id as string] || ''}
                        onChange={(e) => handleSelectChange(ch.id as string, e.target.value)}
                        sx={{ ...commonStyles, width: ch.width }}
                        InputLabelProps={{ shrink: !!selectedValues[ch.id as string] }}
                        autoComplete="off"
                      />
                    )}
                  </FormControl>
                </Box>
              ) : (
                <Typography fontSize="0.75rem" color="white" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: ch.width, width: ch.width }}>
                  {ch.label}
                </Typography>
              )}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
