import { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import {
    Dialog,
    AppBar,
    Tabs,
    Tab,
    DialogContent,
    Button,
    Box,
    Container,
    DialogActions,
    DialogTitle,
    DialogContentText
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import LabeledTextField from '../labeledTextField/labeledTextField';
import { Loader } from '../loader';
import {
    tagsTemplate,
    headerTemplate,
    customDimensionsTemplate,
    dimensionValueTemplate,
    extensionElementsTemplate,
    settingsTemplate,
    ContextSettingsTemplate,
    labelsTemplate,
    HeadersColumns,
    dimensionValuesColumns,
    ContextColumns,
    TagsAltColumns,
    taxonomyElementTemplate
} from './customObjectsPreData';
import { t } from '@lingui/macro';
import { cContextSettingsColumns, cCustomDimensionValueDetails, cHeaderDetails, cTagDetails, cTaxonomyElement, dataMatchingToNavBar, ElementReference, expandedTaxonomyElement, selectTableProps } from '../../api/types';
import SelectTaxonomies from './selectTaxonomies';
import { getAllTaxonomies } from '../../helpers/taxonomyHelpers';
import GenericGridTable from './genericGridTable';
import axios from 'axios';
import LabelTable from '../labelTable';
import UsageComponent from './usageComponent';
import DataTable from '../dataTable';
import SelectTable from './selectTable';

interface AddDialogProps {
    open: boolean;
    onClose: (updateStatus: "none" | "success" | "error", reason?: string) => void;
    selectTemplate: string;
    id: string | undefined;
    url: string;
}

const CustomObjectsDialog = ({ open, onClose, selectTemplate, id, url }: AddDialogProps) => {
    const [data, setData] = useState<dataMatchingToNavBar | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("details");
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const hasRun = useRef(false);
    const openDataDialog = useRef(false);

    const handleTaxonomySetter = (selectedTaxonomies: any) => {
        setData((prevData: dataMatchingToNavBar | null): dataMatchingToNavBar | null => {
            if (!prevData) return prevData;
            return {
                ...prevData,
                taxonomies: selectedTaxonomies,
            };
        });
    };

    const templateDataSelected = useMemo(() => {
        switch (selectTemplate) {
            case "tags":
                return tagsTemplate();
            case "header":
                return headerTemplate();
            case "labels":
                return labelsTemplate();
            case "customDimensions":
                return customDimensionsTemplate();
            case "dimensionValue":
                return dimensionValueTemplate();
            case "extensionElements":
                return extensionElementsTemplate();
            case "settings":
                return settingsTemplate();
            case "contextSettings":
                return ContextSettingsTemplate();
            case "taxonomyElement":
                return taxonomyElementTemplate();
            default:
                return tagsTemplate();
        }
    }, [selectTemplate]);

    type IncomingData = any;


    const mapDataToTemplate = (template: any, data: Record<string, any>): any => {
        const mappedTemplate = JSON.parse(JSON.stringify(template));

        const updateTemplateField = (templateField: any, dataValue: any): void => {
            if (templateField) {
                templateField.value = dataValue;
            }
        };

        const traverseAndUpdate = (templateSection: any, data: Record<string, any>): void => {
            Object.keys(templateSection).forEach((key) => {
                const templateField = templateSection[key];
                if (templateField && typeof templateField === 'object') {
                    if ('fieldType' in templateField) {
                        const dataValue = data[key as keyof typeof data];
                        if (dataValue !== undefined) {
                            updateTemplateField(templateField, dataValue);
                        }
                    } else {
                        traverseAndUpdate(templateField, data);
                    }
                }
            });
        };

        const addLangToLanguage = (templateSection: any, data: Record<string, any>): void => {
            Object.keys(templateSection).forEach((key) => {
                const templateField = templateSection[key];
                if (templateField && typeof templateField === 'object') {
                    if (key === 'language' && 'lang' in data) {
                        templateField.value = data.lang;
                    } else {
                        addLangToLanguage(templateField, data);
                    }
                }
            });
        };

        Object.keys(mappedTemplate).forEach((sectionKey) => {
            const section = mappedTemplate[sectionKey];
            if (section && typeof section === 'object') {
                traverseAndUpdate(section, data);
                addLangToLanguage(section, data);
            }
        });



        if ('dimensionValueReferences' in data) {
            mappedTemplate.dimensionValueReferences = data.dimensionValueReferences.map((reference: cTaxonomyElement[]) => ({
                ...reference,
            }));
            mappedTemplate.dimensionValueReferences.navBarName = "Dimension Values";
        }

        if ('additionalElementReferences' in data) {
            mappedTemplate.additionalElementReferences = data.additionalElementReferences.map((reference: any) => ({
                ...reference,
            }));
            mappedTemplate.additionalElementReferences.navBarName = "Additional Elements";
        }

        if ('extensionStructureReferences' in data) {
            mappedTemplate.extensionStructureReferences = data.extensionStructureReferences.map((reference: any) => ({
                ...reference,
            }));
            mappedTemplate.extensionStructureReferences.navBarName = "Extension Structure";
        }

        if ('hierarchyElementReferences' in data) {
            mappedTemplate.hierarchyElementReferences = data.hierarchyElementReferences.map((reference: any) => ({
                ...reference,
            }));
            mappedTemplate.hierarchyElementReferences.navBarName = "Hierarchy Elements";
        }

        if ('labels' in data) {
            mappedTemplate.customLabels = data.labels;
            mappedTemplate.customLabels.navBarName = "Labels";
        }


        if ('taxonomies' in data) {
            mappedTemplate.taxonomies = data.taxonomies
            mappedTemplate.taxonomies.navBarName = "Taxonomies";
            localStorage.setItem('selectedTaxonomies', JSON.stringify(data.taxonomies));
        }
        console.log(mappedTemplate)
        return mappedTemplate;
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (url && id) {
                    const response = await axios.get(`api/custom-objects/${url}/${id}`);
                    const apiData: IncomingData = response.data;
                    const mappedLabelsTemplate = mapDataToTemplate(templateDataSelected, apiData);
                    setData(mappedLabelsTemplate);
                } else {
                    setData(templateDataSelected);
                }

                const allTaxonomies = await getAllTaxonomies();
                const selectedTaxonomyIds = allTaxonomies
                    .filter(taxonomy => taxonomy.isSelected)
                    .map(taxonomy => taxonomy.id);

                setData(prevData => {
                    if (!prevData || !prevData.taxonomies) return prevData;
                    return {
                        ...prevData,
                        taxonomies: selectedTaxonomyIds
                    };
                });
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setIsLoading(false);
                hasRun.current = true;
            }
        };

        if (!hasRun.current) {
            fetchData();
        }
    }, [url, id, templateDataSelected]);


    const handleTabChange = (event: ChangeEvent<{}>, newValue: string) => {
        setActiveTab(newValue);
    };

    const pagesShouldNotBeMapped = useMemo(() => [
        "globals",
        "usage",
        "dimensionValueReferences",
        "taxonomies",
        "extension",
        "childDimensionValues",
        "childElements",
        "documentation",
        "labels",
        "additionalElementReferences",
        "extensionStructureReferences",
        "hierarchyElementReferences",
        "customLabels",
        "customHeaders",
        "customTags",
        "customDimensionValues",
        "dimensionLabels",
        "contexts",
        "valueLabels",
        "validators",
    ], []);

    //extensionStructure / hierarchyElementReferences

    const cleanData = (data: any): any => {
        const cleanField = (field: any): any => {
            if (field && typeof field === 'object' && 'fieldType' in field && 'value' in field) {
                return field.value;
            }
            if (field && typeof field === 'object' && !Array.isArray(field)) {
                return Object.fromEntries(Object.entries(field).map(([key, value]) => [key, cleanField(value)]));
            }
            return field;
        };
        return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, cleanField(value)]));
    };

    const handleApply = () => {
        const cleanedData = cleanData(data);
        console.log(cleanedData);
        onClose("success");
        hasRun.current = false;
        openDataDialog.current = true;
        setActiveTab("details");
    };

    const handleClose = () => {
        onClose("none");
        hasRun.current = false;
    };

    const handleChange = (
        event: ElementReference | cTaxonomyElement | string | boolean,
        key: string
    ) => {
        let inputValue: any;

        if (typeof event === "string" || typeof event === "boolean") {
            inputValue = event;
        } else if ('target' in event && event.target instanceof HTMLInputElement) {
            const { type, checked, value } = event.target;
            inputValue = type === "checkbox" ? checked : value;
        } else if ('dimensionValue' in event) {
            inputValue = event;
        } else if ('namespaceAbbreviation' in event) {
            inputValue = event;
        }
        setData((prevData: dataMatchingToNavBar | null) => {
            if (!prevData || !prevData[activeTab]) return prevData;
            const updatedData = {
                ...prevData,
                [activeTab]: {
                    ...prevData[activeTab],
                    [key]: {
                        ...prevData[activeTab][key],
                        value: inputValue,
                    },
                },
            };
            return updatedData;
        });
    };

    const handleDelete = async () => {
        try {
            await axios.delete('/api/test/delete'); //TODO Adjust the endpoint as needed
            console.log('Deleted successfully');
            setIsDeleteDialogOpen(false);
            onClose("success", "deleted");
        } catch (error) {
            console.error('Delete failed', error);
            onClose("error", "delete failed");
        }
    };

    const handleOpenDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const tabKeys = Object.keys(data || {}).filter(key => key !== 'id');

    return (
        <>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>{t`Delete Confirmation`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t`Are you sure you want to delete this item?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        {t`Cancel`}
                    </Button>
                    <Button onClick={handleDelete} color="secondary" variant="contained">
                        {t`Delete`}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Main dialog */}
            <Dialog
                open={open}
                maxWidth={false}
                fullWidth

                onClose={handleClose}
            >
                <AppBar position="static">
                    <Tabs value={activeTab} onChange={handleTabChange}>
                        {tabKeys.filter(key => key !== 'globals').map(key => (
                            <Tab
                                sx={{ color: "white" }}
                                key={key}
                                value={key}
                                label={data?.[key]?.navBarName || key}
                            />
                        ))}
                    </Tabs>
                </AppBar>
                <DialogContent sx={{ maxHeight: "85vh", minHeight: "60vh", overflowX: "hidden", }}>
                    {isLoading && (
                        <Loader height={450} />
                    )}
                    {!pagesShouldNotBeMapped.includes(activeTab) && (
                        <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", p: 0, m: 0 }} maxWidth="xl" >
                            {Object.entries(data?.[activeTab] || {})
                                .filter(([key, item]: [string, any]) => {
                                    const isValidItem = (item: any) =>
                                        !(item.key === "" && item.name === "" && item.value === null);
                                    return item !== undefined && isValidItem(item);
                                })
                                .map(([key, item]: [string, any], index: number) => (
                                    key !== 'navBarName' &&
                                    key !== undefined && (
                                        <Grid size={12} key={key + index}>
                                            <LabeledTextField
                                                id={id}
                                                endpointUrl={item?.endpointUrl || undefined}
                                                label={item?.name || ""}
                                                placeholder={item?.placeholder || ""}
                                                options={item?.options || []}
                                                fieldType={item?.fieldType}
                                                value={item?.value}
                                                onValueChange={(event: any) => handleChange(event, key)}
                                                alternative={item?.alternative}
                                                urlType={item?.urlType}
                                            />
                                        </Grid>
                                    )
                                ))}
                        </Container>
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) && (
                        <>
                            {activeTab === "usage" && (<>
                                <UsageComponent id={id} />
                            </>)}

                            {activeTab === "valueLabels" && (
                                <LabelTable
                                    initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                    onLabelsChange={(labels: any) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: labels,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                    fieldType='documentation'
                                />
                            )}
                            {activeTab === "customHeaders" && (
                                <DataTable<cHeaderDetails>
                                    columns={HeadersColumns()}
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}
                            {activeTab === "customTags" && (
                                <DataTable<cTagDetails>
                                    columns={TagsAltColumns()}
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}
                            {activeTab === "contexts" && (
                                <DataTable<cContextSettingsColumns>
                                    columns={ContextColumns()}
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}
                            {activeTab === "customDimensionValues" && (
                                <DataTable<cCustomDimensionValueDetails>
                                    columns={dimensionValuesColumns()}
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}
                            {(activeTab === "dimensionLabels") && (
                                data?.id === "contextSettings" ? (
                                    <GenericGridTable
                                        type="contextDimensionValues"
                                        data={data?.dimensionValueRefrences || []}
                                        setData={(newData: expandedTaxonomyElement) => {

                                        }}
                                    />
                                ) : (
                                    data?.id === "customDimensions" ? (
                                        <LabelTable
                                            initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                            onLabelsChange={(labels: any) => {
                                                setData((prevData: dataMatchingToNavBar | null) => {
                                                    if (!prevData || !prevData[activeTab]) return prevData;

                                                    const updatedData = {
                                                        ...prevData,
                                                        [activeTab]: {
                                                            ...prevData[activeTab],
                                                            dataTable: {
                                                                ...prevData[activeTab]?.dataTable,
                                                                data: labels,
                                                            },
                                                        },
                                                    };
                                                    return updatedData;
                                                });
                                            }}
                                            fieldType='extensionElement'
                                        />

                                    ) :
                                        <GenericGridTable
                                            type="dimension"
                                            data={data?.dimensionValueRefrences || []}
                                            setData={(newData: expandedTaxonomyElement) => {

                                            }}
                                        />
                                )
                            )}
                            {activeTab === "validators" && (
                                <GenericGridTable
                                    type="contextDimensionValues"
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    setData={(newData: expandedTaxonomyElement) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                            {(activeTab === "childDimensionValues" || activeTab === "childElements") && (
                                <GenericGridTable
                                    type="childDimensionValues"
                                    data={data?.[activeTab]?.dataTable?.data || []}
                                    setData={(newData: expandedTaxonomyElement) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                        </>
                    )}
                    {(activeTab === "valueLabel") && (
                        <SelectTable
                            data={data?.[activeTab]?.data || []}
                            setData={(newData: selectTableProps[]) => {
                                setData((prevData: dataMatchingToNavBar | null) => {
                                    if (!prevData) return prevData;

                                    const updatedData = {
                                        ...prevData,
                                        valueLabel: {
                                            navBarName: t`Value Labels`,
                                            data: newData,
                                        },
                                    };
                                    return updatedData;
                                });
                            }}
                        />
                    )}
                    {pagesShouldNotBeMapped.includes(activeTab) && (
                        <>
                            {activeTab === "hierarchyElementReferences" && (
                                <>
                                    <GenericGridTable
                                        type={"extensionStructure"}
                                        data={data?.hierarchyElementReferences || []}
                                        setData={(newData: expandedTaxonomyElement) => {
                                            setData((prevData: dataMatchingToNavBar | null) => {
                                                if (!prevData || !prevData[activeTab]) return prevData;

                                                const updatedData = {
                                                    ...prevData,
                                                    [activeTab]: {
                                                        ...prevData[activeTab],
                                                        dataTable: {
                                                            ...prevData[activeTab]?.dataTable,
                                                            data: newData,
                                                        },
                                                    },
                                                };
                                                return updatedData;
                                            });
                                        }}
                                    />
                                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", p: 0, m: 0 }} maxWidth="xl" >
                                        {Object.entries(data?.globals || {})
                                            .filter(([key, item]: [string, any]) => key === 'customTaxonomyGroup' && item !== undefined)
                                            .map(([key, item]: [string, any], index: number) => (
                                                <Grid size={12} key={key + index}>
                                                    <LabeledTextField
                                                        id={id}
                                                        endpointUrl={item?.endpointUrl || undefined}
                                                        label={item?.name || ""}
                                                        placeholder={item?.placeholder || ""}
                                                        options={item?.options || []}
                                                        fieldType={item?.fieldType}
                                                        value={item?.value}
                                                        onValueChange={(event: any) => handleChange(event, key)}
                                                        alternative={item?.alternative}
                                                    />
                                                </Grid>
                                            ))}
                                    </Container>
                                </>
                            )}
                            {(activeTab === "extension" || activeTab === "extensionStructureReferences") && (
                                <>
                                    {data?.id === "tags" ? (
                                        <>
                                            <GenericGridTable
                                                type={"extensionStructure"}
                                                data={data?.hierarchyElementReferences.filter((item: any) => item.key !== "customTaxonomyGroup") || []}
                                                setData={(newData: expandedTaxonomyElement) => {
                                                    setData((prevData: dataMatchingToNavBar | null) => {
                                                        if (!prevData || !prevData[activeTab]) return prevData;

                                                        const updatedData = {
                                                            ...prevData,
                                                            [activeTab]: {
                                                                ...prevData[activeTab],
                                                                dataTable: {
                                                                    ...prevData[activeTab]?.dataTable,
                                                                    data: newData,
                                                                },
                                                            },
                                                        };
                                                        return updatedData;
                                                    });
                                                }}
                                            />
                                            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", p: 0, m: 0 }} maxWidth="xl" >
                                                {Object.entries(data?.globals || {})
                                                    .filter(([key, item]: [string, any]) => key === 'customTaxonomyGroup' && item !== undefined)
                                                    .map(([key, item]: [string, any], index: number) => (
                                                        <Grid size={12} key={key + index}>
                                                            <LabeledTextField
                                                                id={id}
                                                                endpointUrl={item?.endpointUrl || undefined}
                                                                label={item?.name || ""}
                                                                placeholder={item?.placeholder || ""}
                                                                options={item?.options || []}
                                                                fieldType={item?.fieldType}
                                                                value={item?.value}
                                                                onValueChange={(event: any) => handleChange(event, key)}
                                                                alternative={item?.alternative}
                                                            />
                                                        </Grid>
                                                    ))}
                                            </Container>
                                        </>
                                    ) : (
                                        <GenericGridTable
                                            type="onlyDimension"
                                            data={data?.dimensionValueRefrences || []}
                                            setData={(newData: expandedTaxonomyElement) => {
                                                setData((prevData: dataMatchingToNavBar | null) => {
                                                    if (!prevData || !prevData[activeTab]) return prevData;

                                                    const updatedData = {
                                                        ...prevData,
                                                        [activeTab]: {
                                                            ...prevData[activeTab],
                                                            dataTable: {
                                                                ...prevData[activeTab]?.dataTable,
                                                                data: newData,
                                                            },
                                                        },
                                                    };
                                                    return updatedData;
                                                });
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {activeTab === "taxonomies" && (
                                <SelectTaxonomies
                                    isDialog={false}
                                    open={true}
                                    onClose={() => { }}
                                    onApply={handleTaxonomySetter}
                                />
                            )}
                        </>
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) && (activeTab === "additionalElementReferences") && (
                        <GenericGridTable
                            type={"element"}
                            data={data?.[activeTab] || []}
                            setData={(newData: expandedTaxonomyElement) => {
                                setData((prevData: dataMatchingToNavBar | null) => {
                                    if (!prevData || !prevData[activeTab]) return prevData;

                                    const updatedData = {
                                        ...prevData,
                                        [activeTab]: {
                                            ...prevData[activeTab],
                                            dataTable: {
                                                ...prevData[activeTab]?.dataTable,
                                                data: newData,
                                            },
                                        },
                                    };
                                    return updatedData;
                                });
                            }}
                        />
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) && (activeTab === "dimensionValueReferences") && (
                        <>
                            {data?.id === "tags" && (
                                <GenericGridTable
                                    type={"tagsDimensionValues"}
                                    data={data?.dimensionValueReferences || []}
                                    setData={(newData: expandedTaxonomyElement) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;
                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                            {data?.id === "labels" && (
                                <>
                                    <GenericGridTable
                                        type={"labelDimensionValues"}
                                        data={data?.dimensionValueReferences || []}
                                        setData={(newData: expandedTaxonomyElement) => {
                                            setData((prevData: dataMatchingToNavBar | null) => {
                                                if (!prevData) return prevData;
                                                const updatedData = {
                                                    ...prevData,
                                                    dimensionValueReferences: newData
                                                };
                                                console.log(updatedData);
                                                return updatedData;
                                            });
                                        }}
                                    />
                                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", p: 0, m: 0 }} maxWidth="xl" >
                                        {Object.entries(data?.globals || {})
                                            .filter(([key, item]: [string, any]) => key === 'typedMemberValue' && item !== undefined)
                                            .map(([key, item]: [string, any], index: number) => (
                                                <Grid size={12} key={key + index}>
                                                    <LabeledTextField
                                                        id={id}
                                                        endpointUrl={item?.endpointUrl || undefined}
                                                        label={item?.name || ""}
                                                        placeholder={item?.placeholder || ""}
                                                        options={item?.options || []}
                                                        fieldType={item?.fieldType}
                                                        value={item?.value}
                                                        onValueChange={(event: any) => handleChange(event, key)}
                                                        alternative={item?.alternative}
                                                    />
                                                </Grid>
                                            ))}
                                    </Container>
                                </>
                            )}
                            {data?.id === "header" && (
                                <GenericGridTable
                                    type={"headerDimensionValues"}
                                    data={data?.[activeTab]?.additionalElements || []}
                                    setData={(newData: expandedTaxonomyElement) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                            {data?.id !== "tags" && data?.id !== "labels" && data?.id !== "header" && (
                                <GenericGridTable
                                    type={"contextDimensionValues"}
                                    data={data?.dimensionValueReferences || []}
                                    setData={(newData: expandedTaxonomyElement) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            )}
                        </>
                    )}

                    {pagesShouldNotBeMapped.includes(activeTab) && (activeTab === "documentation" || activeTab === "customLabels") && (
                        <>
                            {data?.id === "settings" && activeTab === "customLabels" ? (
                                <GenericGridTable
                                    type={"settingsLabels"}
                                    data={data?.customLabels || []}
                                    setData={(newData: expandedTaxonomyElement) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: newData,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            ) : activeTab === "documentation" ? (
                                <LabelTable
                                    initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                    onLabelsChange={(labels: any) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: labels,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                    fieldType='documentation'
                                />
                            ) : (
                                <LabelTable
                                    initialLabels={data?.[activeTab]?.dataTable?.data || []}
                                    onLabelsChange={(labels: any) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData || !prevData[activeTab]) return prevData;

                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    dataTable: {
                                                        ...prevData[activeTab]?.dataTable,
                                                        data: labels,
                                                    },
                                                },
                                            };
                                            return updatedData;
                                        });
                                    }}
                                    fieldType='default'
                                />
                            )}
                        </>
                    )}
                </DialogContent>
                <Box display="flex" justifyContent="flex-end" m={3}>
                    <Container
                        maxWidth={"xl"}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid>
                            {id && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        border: "1px solid #d32f2f",
                                        backgroundColor: "#d32f2f",
                                        ':hover': {
                                            backgroundColor: "#b71c1c"
                                        }
                                    }}
                                    onClick={handleOpenDeleteDialog}
                                >
                                    {t`Delete`}
                                </Button>
                            )}
                        </Grid>
                        <Grid>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t`Close`}
                            </Button>
                            <Button sx={{ ml: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={handleApply}
                            >
                                {t`Apply`}
                            </Button>
                        </Grid>
                    </Container>
                </Box>
            </Dialog >
        </>
    );
};

export default CustomObjectsDialog;
