import { AppBar, Tab, Tabs, Toolbar, Button, useTheme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { t } from "@lingui/macro";
import { Link, useLocation } from "react-router-dom";
import tokenStorageHelper from "../helpers/tokenStorage";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import SelectTaxonomies from "./customObjects/selectTaxonomies";
import { getAllTaxonomies, handleTaxonomiesChange } from "../helpers/taxonomyHelpers";
import { TaxonomyListProps } from "../api/types";

interface NavbarTab {
  path: string;
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap>;
  hasAccess?: boolean;
}

interface DecodedToken {
  hasConversionManagement: string;
  hasUserManagement: string;
  hasTaxonomyManagement: string;
}

const NavbarCustomObjects = ({
  taxonomyChanged,
  setTaxonomyChanged,
}: {
  taxonomyChanged: boolean;
  setTaxonomyChanged: (value: boolean) => void;
}) => {
  const theme = useTheme();
  const location = useLocation();

  const [taxonomiesList, setTaxonomiesList] = useState<TaxonomyListProps[]>([]);
  const [selectedTaxonomies, setSelectedTaxonomies] = useState<TaxonomyListProps[]>([]);
  const [openTaxonomySelector, setOpenTaxonomySelector] = useState(false);

  const decodeToken = jwtDecode(tokenStorageHelper.token) as DecodedToken;
  const hasTaxonomyAccess = decodeToken.hasTaxonomyManagement === "True";

  useEffect(() => {
    const fetchTaxonomies = async () => {
      try {
        const allTaxonomies: TaxonomyListProps[] = await getAllTaxonomies();
        localStorage.setItem("allTaxonomies", JSON.stringify(allTaxonomies));
        setTaxonomiesList(allTaxonomies);
        setSelectedTaxonomies(allTaxonomies.filter(taxonomy => taxonomy.isSelected));
      } catch (error) {
        console.error("Error fetching taxonomies:", error);
      }
    };

    fetchTaxonomies();
  }, []);

  const handleApplySelectedTaxonomies = (selectedTaxonomyIds: string[]) => {
    setTaxonomyChanged(true);
    const updatedList = taxonomiesList.map((taxonomy) => ({
      ...taxonomy,
      isSelected: selectedTaxonomyIds.includes(taxonomy.id),
    }));

    setTaxonomiesList(updatedList);
    setSelectedTaxonomies(updatedList.filter(taxonomy => taxonomy.isSelected));
    handleTaxonomiesChange(updatedList);
  };

  const tabs: NavbarTab[] = [
    { path: "/customobjects/tags", label: t`Tags`, hasAccess: hasTaxonomyAccess },
    { path: "/customobjects/headers", label: t`Headers`, hasAccess: hasTaxonomyAccess },
    { path: "/customobjects/labels", label: t`Labels`, hasAccess: hasTaxonomyAccess },
    { path: "/customobjects/dimensions", label: t`Dimensions`, hasAccess: hasTaxonomyAccess },
    { path: "/customobjects/dimensionvalues", label: t`Dimension Values`, hasAccess: hasTaxonomyAccess },
    { path: "/customobjects/extensionelements", label: t`Extension Elements`, hasAccess: hasTaxonomyAccess },
    { path: "/customobjects/settings", label: t`Settings`, hasAccess: hasTaxonomyAccess },
    { path: "/customobjects/contextsettings", label: t`Context Settings`, hasAccess: hasTaxonomyAccess },
    { path: "/customobjects/consistencycheck", label: t`Consistency Check`, hasAccess: hasTaxonomyAccess },
    { path: "/customobjects/globalrequests", label: t`Global Requests`, hasAccess: hasTaxonomyAccess },
  ];

  if (!tokenStorageHelper.isUserLoggedIn) return null;

  let currentPath: string | boolean = location.pathname.toLowerCase();
  if (!tabs || !tabs.map((tab) => tab.path).includes(currentPath as string)) {
    currentPath = false;
  }

  const tabElements = tabs.map((tab) => (
    <Tab
      key={tab.label}
      component={Link}
      to={tab.path}
      value={tab.path}
      label={tab.label}
      iconPosition="start"
      disabled={!tab.hasAccess}
      style={{
        color: tab.hasAccess ? theme.palette.common.black : "grey",
      }}
      {...a11yProps(tab.label)}
    />
  ));

  return (
    <AppBar
      sx={{
        mt: 9,
        height: 48,
        zIndex: theme.zIndex.drawer - 1,
        backgroundColor: "#f8f8f8",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Toolbar>
        {tabs && tabs.length > 0 && (
          <Tabs value={currentPath}>{tabElements}</Tabs>
        )}
        <Button
          sx={{ marginLeft: "auto", display: "flex" }}
          variant="text"
          onClick={() => setOpenTaxonomySelector(!openTaxonomySelector)}
        >
          {selectedTaxonomies.length > 1 && (
            <>
              {selectedTaxonomies[0].name}
              {" + "}
              {selectedTaxonomies.length - 1}
              {" "}{t`more`}
            </>
          )}
          {selectedTaxonomies.length === 1 && (
            <>{selectedTaxonomies[0].name}</>
          )}
          {selectedTaxonomies.length === 0 && <>{t`Select Taxonomies`}</>}
        </Button>
      </Toolbar>
      <SelectTaxonomies
        open={openTaxonomySelector}
        isDialog={true}
        onClose={() => setOpenTaxonomySelector(false)}
        onApply={handleApplySelectedTaxonomies}
      />
    </AppBar>
  );
};

function a11yProps(value: string) {
  return {
    id: `simple-tab-${value}`,
    "aria-controls": `simple-tabpanel-${value}`,
  };
}

export default NavbarCustomObjects;
