import { useState } from 'react';
import { ListItemText, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  cTaxonomyElement,
  cCompanyGroup,
  cDateSpectrums,
  cDimensionValueReference,
  LabeledTextFieldProps,
  CustomDimensionValue,
  ElementReference,
  DimensionValue
} from '../../api/types';
import { ElementLabel } from './elementLabel';
import { EndpointLabel } from './endpointLabel';
import { SearchSelectLabel } from './searchSelectLabel';
import { SelectLabel } from './selectLabel';
import { TagGroupLabel } from './tagGroupLabel';
import { DateSpectrumLabel } from './dateSpectrumLabel';
import { CombinedCheckBoxLabel } from './checkboxLabel';
import { CompanyLabel } from './companyLabel';
import { DimensionLabel } from './dimensionLabel';

const LabeledTextField = ({
  id,
  label,
  fieldType,
  options,
  placeholder,
  value,
  disabled,
  endpointUrl,
  alternative = false,
  onValueChange,
  urlType,
}: LabeledTextFieldProps) => {
  const [selectedValue, setSelectedValue] = useState<
    cTaxonomyElement | cDimensionValueReference | cCompanyGroup | cDateSpectrums | CustomDimensionValue | ElementReference | string | null
  >(null);

  /* 
    useEffect(() => {
    if (value) {
      setSelectedValue(value as cTaxonomyElement | cDimensionValueReference | cCompanyGroup | cDateSpectrums | CustomDimensionValue | ElementReference | string);
    }
  }, [value]);
  */

  const renderFieldType = () => {
    switch (fieldType) {
      case "dimension":
        return (
          <DimensionLabel
            label={label}
            disabled={disabled}
            onValueChange={onValueChange as ((event: DimensionValue) => void)}
            placeholder={placeholder}
            preDefinedOptions={options as unknown as DimensionValue[]}
            selectedValue={selectedValue as DimensionValue}
            setSelectedValue={setSelectedValue}
          />
        );
      case "element":
        return (
          <ElementLabel
            label={label}
            disabled={disabled}
            onValueChange={onValueChange as ((event: ElementReference) => void) | undefined}
            placeholder={placeholder}
            preDefinedOptions={options as ElementReference[]}
            selectedValue={selectedValue as ElementReference}
            setSelectedValue={setSelectedValue}
          />
        );
      case "endpoint":
        return (
          <EndpointLabel
            label={label}
            value={value as string}
            disabled={disabled}
            onValueChange={(e) =>
              onValueChange && onValueChange(e)
            }
            placeholder={placeholder}
            setSelectedValue={setSelectedValue}
            endpoint={endpointUrl as string}
            urlType={urlType}
          />
        );
      case "searchSelect":
      case "languages":
        return (
          <SearchSelectLabel
            fieldType={fieldType}
            label={label}
            disabled={disabled}
            onValueChange={(e) =>
              onValueChange && onValueChange(e)
            }
            placeholder={placeholder}
            value={value as string}
          />
        );
      case "select":
        return (
          <SelectLabel
            label={label}
            value={value as string}
            disabled={disabled}
            onValueChange={(e) =>
              onValueChange && onValueChange(e)
            }
            options={options as string[]}
            placeholder={placeholder}
          />
        );
      case "tagGroup":
        return (
          <TagGroupLabel
            label={label}
            disabled={disabled}
            onValueChange={(e) =>
              onValueChange && onValueChange(e)
            }
            placeholder={placeholder}
            value={value as string}
          />
        );
      case "dateSpectrum":
        return (
          <DateSpectrumLabel
            label={label}
            disabled={disabled}
            onValueChange={(e) => {
              onValueChange && onValueChange(e);
            }}
            placeholder={placeholder}
            value={value as string}
            setSelectedValue={setSelectedValue}
          />
        );
      case "checkbox":
        return (
          <CombinedCheckBoxLabel
            label={label}
            onValueChange={(e) =>
              onValueChange && onValueChange(e.target.checked)
            }
            value={value as boolean}
            alternative={alternative}
          />
        );
      case "alternateCheckbox":
        return (
          <CombinedCheckBoxLabel
            label={label}
            onValueChange={(e) =>
              onValueChange && onValueChange(e.target.checked)
            }
            value={value as boolean}
            alternative={true}
          />
        );
      case "company":
        return (
          <CompanyLabel
            label={label}
            disabled={disabled}
            onValueChange={
              onValueChange as ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
            }
            placeholder={placeholder}
            value={value as string}
            selectedValue={selectedValue as cCompanyGroup}
            setSelectedValue={setSelectedValue}
          />
        );
      case "text":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }} maxWidth={"false"}>
            <Grid size={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={6}>
              <TextField
                autoComplete='off'
                fullWidth
                sx={{ width: "100%" }}
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                variant="standard"
                placeholder={placeholder || ""}
                value={value || ""}
                disabled={disabled}
                onChange={(e) =>
                  onValueChange && onValueChange(e.target.value)
                }
              />
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return renderFieldType();
};

export default LabeledTextField;