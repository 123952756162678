import React from "react";
import { TableCell } from "@mui/material";
import { DataTableColumn } from "./dataTable";

interface DataTableCellProps<T> {
  row?: T;
  column?: DataTableColumn<T>;
  nested?: boolean;
}

export default function DataTableCell<T>({
  row,
  column,
  nested,
}: DataTableCellProps<T>) {
  const StoredTaxonomies = localStorage.getItem("allTaxonomies");

  // Renderers
  const renderTaxonomies = (row: any): React.ReactNode => {
    if (row && Array.isArray(row.taxonomies)) {
      const taxonomies = JSON.parse(StoredTaxonomies || "[]");
      return row.taxonomies.map((taxonomy: string) => {
        const taxonomyObj = taxonomies.find((t: { id: string }) => t.id === taxonomy);
        return (
          <div key={taxonomy}>
            {taxonomyObj ? taxonomyObj.name : taxonomy}
          </div>
        );
      });
    }
    return null;
  };

  const renderCompany = (row: any): React.ReactNode => {
    if (row && row.company && typeof row.company === "object") {
      return <div>{row.company.name || ""}</div>;
    }
    return null;
  };

  const renderElement = (row: any): React.ReactNode => {
    if (
      row &&
      row.elementReference &&
      typeof row.elementReference === "object" &&
      row.elementReference.extension &&
      typeof row.elementReference.extension === "object"
    ) {
      return <div>{row.elementReference.extension.name || ""}</div>;
    }
    return null;
  };

  const renderDimensionValues = (row: any): React.ReactNode => {
    if (row && Array.isArray(row.DimensionValueReferences)) {
      return row.DimensionValueReferences.map((ref: any, idx: number) => (
        <div key={idx}>{ref.DimensionValue?.DimensionName || ""}</div>
      ));
    }
    return null;
  };

  const renderAdditionalElements = (row: any): React.ReactNode => {
    if (row && Array.isArray(row.additionalElements)) {
      return <div>{row.additionalElements.join(", ")}</div>;
    }
    return null;
  };

  const renderDefault = (row: any, column: any): React.ReactNode => {
    if (column) {
      return column.valueFormatter
        ? column.valueFormatter(row as T)
        : row?.[column.key as keyof T];
    }
    return null;
  };

  const renderContent = (): React.ReactNode => {
    switch (column?.key) {
      case "taxonomies":
        return renderTaxonomies(row);
      case "company":
        return renderCompany(row);
      case "element":
        return renderElement(row);
      case "dimensionValue":
        return renderDimensionValues(row);
      case "additionalElement":
        return renderAdditionalElements(row);
      default:
        return renderDefault(row, column);
    }
  };

  return (
    <TableCell
      sx={{
        overflowY: "auto",
        borderBottom: nested ? "unset" : "default",
        minWidth: column?.width ? column.width : undefined,
        maxWidth: column?.width ? column.width : undefined,
        overflow: column?.shouldWrapVertically ? "hidden" : "unset",
        textOverflow: column?.shouldWrapVertically ? "ellipsis" : "unset",
        whiteSpace: column?.shouldWrapVertically ? "normal" : "nowrap",
        wordWrap: column?.shouldWrapVertically ? "break-word" : "normal",
      }}
    >
      {renderContent()}
    </TableCell>
  );
}
