import { t } from "@lingui/macro";
import {
  Container,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useGetQuery } from "../../hooks/useGetQuery";
import ContentHeaderToolbar from "../contentHeaderToolbar";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DataTable from "../dataTable";
import { ApiTaxonomy } from "../../api/types";
import { taxonomiesTableColumns } from "./taxonomiesTableColumns";
import { useState } from "react";
import TaxonomyDetailsDialog from "./taxonomyDetailsDialog";
import TaxonomyImportDialog, { ImportType } from "./taxonomyImportDialog";
import { ContainerWrapper } from "../containerWrapper";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import EmptyTableAlert from "../emptyTableAlert";
import InboxIcon from "@mui/icons-material/Inbox";
import { useNavigate } from "react-router-dom";
import AlertSnackbar from "../alertSnackbar";

const Taxonomies = () => {
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const { data, error, loading } = useGetQuery<ApiTaxonomy[]>(
    `/api/taxonomies/all?cb=${cacheBuster}`
  );
  const navigate = useNavigate();
  const [selectedTaxonomyId, setSelectedTaxonomyId] = useState<string>();
  const [currentImportType, setCurrentImportType] = useState<ImportType>();
  const [alertMessage, setAlertMessage] = useState<
    | {
      message: string;
      severity: "error" | "warning" | "info" | "success" | undefined;
    }
    | undefined
  >(undefined);

  function handlecloseDialog(
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) {
    setSelectedTaxonomyId(undefined);
    setAlertMessage({
      message: reason || "",
      severity: updateStatus === "none" ? undefined : updateStatus,
    });
    if (updateStatus === "success") {
      setCacheBuster(crypto.randomUUID());
    }
  }

  const handleClosedImportDialog = (
    updateStatus: "none" | "success" | "error",
    reason: string | undefined
  ) => {
    setCurrentImportType(undefined);
    if (updateStatus === "success") {
      setCacheBuster(crypto.randomUUID());
    } else {
      if (updateStatus === "error" && reason) {
        setAlertMessage({
          message: reason || "",
          severity: updateStatus,
        });
      }
    }
  };

  const handleClickRow = (rowId: string) => {
    setAlertMessage({
      message: "",
      severity: undefined,
    });
    navigate(`/taxonomies/${rowId}`);
  };

  return (
    <>
      <ContainerWrapper>
        <Container
          maxWidth={false}
          sx={{
            ml: 0,
            mb: 10,
            overflow: "auto",
          }}
          component="div"
        >
          <Paper
            elevation={0}
            sx={{
              minHeight: 650,
              overflow: "auto",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container maxWidth={false}>
              <ContentHeaderToolbar
                titleIcon={<InsertDriveFileOutlinedIcon fontSize="large" />}
                title={t`Taxonomies`}
                actions={[
                  {
                    id: "scheme",
                    disabled: false,
                    title: t`Schemes`,
                    turnedOn: false,
                    icon: NoteAddOutlinedIcon,
                    clickAction: () => navigate("/permissionschemes"),
                  },
                  {
                    id: "importData",
                    disabled: false,
                    title: t`Import files`,
                    turnedOn: false,
                    icon: NoteAddOutlinedIcon,
                    clickAction: () => setCurrentImportType("data"),
                  },
                  {
                    id: "importTax",
                    disabled: false,
                    title: t`Import Taxonomy`,
                    turnedOn: false,
                    icon: NoteAddOutlinedIcon,
                    clickAction: () => setCurrentImportType("taxonomy"),
                  },
                ]}
                showIcons
                CreateButton={true}
                createButtonWidth={220}
              />
            </Container>
            {!loading && error && (
              <EmptyTableAlert
                severity="info"
                subTitle={t`Could not retrieve taxonomies. Please try again later`}
                mainIcon={
                  <InboxIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />
                }
                showIcon={true}
              />
            )}
            {!loading && !error && data && (
              <DataTable<ApiTaxonomy>
                columns={taxonomiesTableColumns()}
                data={data}
                onClickRow={handleClickRow}
              />
            )}
            {loading && (
              <Container maxWidth={false} sx={{ minHeight: 550 }}>
                <Box
                  sx={{
                    minHeight: 550,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Container>
            )}
          </Paper>
        </Container>
      </ContainerWrapper>
      <TaxonomyDetailsDialog
        onClose={handlecloseDialog}
        open={selectedTaxonomyId !== undefined}
        taxonomyId={selectedTaxonomyId}
      />
      <TaxonomyImportDialog
        headerText={
          currentImportType === "data"
            ? t`Import common xbrl files`
            : currentImportType === "taxonomy"
              ? t`Import Taxonomy`
              : t`Import Taxonomy`
        }
        open={currentImportType !== undefined}
        onClose={handleClosedImportDialog}
        importType={currentImportType}
      />
      <AlertSnackbar
        alertMessage={alertMessage}
        updateAlertStatus={setAlertMessage}
      />
    </>
  );
};

export default Taxonomies;
