import { TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { DataTableColumn, DataTableProps } from "./dataTable";
import DataTableCell from "./dataTableCell";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export interface DataTableRowProps<T extends { id: string }> {
  index: number;
  rowData: T;
  columns: DataTableColumn<T>[];
  onClickRow?: (rowid: string) => void;
  expandedRowComponent?: DataTableProps<T>["expandedRowComponent"];
  expandOnRowClick?: boolean;
  nested?: boolean;
}

export default function DataTableRow<T extends { id: string }>({
  index,
  rowData,
  columns,
  onClickRow,
  expandedRowComponent,
  expandOnRowClick,
  nested,
}: DataTableRowProps<T>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { expandedRowComponent: ExpandedRowComponent } = {
    expandedRowComponent,
  } as DataTableRowProps<T>;
  return (
    <>
      <TableRow
        key={`table_row_${rowData.id}`}
        sx={{
          cursor: "default",
          backgroundColor: (theme) =>
            index % 2 === 0 ? "white" : theme.palette.grey[200],
          "&:hover": {
            backgroundColor: (theme) => theme.palette.grey[400],
          },
        }}
        onClick={(_) =>
          expandOnRowClick
            ? setIsExpanded((prev) => !prev)
            : onClickRow && onClickRow(rowData.id as string)
        }
        tabIndex={-1}
      >
        {expandedRowComponent && (
          <TableCell
            sx={{
              width: 50,
            }}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </TableCell>
        )}
        {columns.map((column) => (
          <DataTableCell
            key={`cell_${rowData.id}_${column.key as string}`}
            row={rowData}
            column={column}
            nested={nested}
          />
        ))}
      </TableRow>
      {ExpandedRowComponent && (
        <ExpandedRowComponent
          rowData={rowData}
          columnCount={columns.length}
          isOpen={isExpanded}
        />
      )}
    </>
  );
}
