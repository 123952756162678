import { t } from "@lingui/macro"
import { ColumnProps, cTaxonomyElement, dataMatchingToNavBar, expandedTaxonomyElement, TaxonomyListProps } from "../../api/types"

/*  Custom Object preDifined Data
------------------------------------------------------------------------ */

// - Row Widths

const labelRowWidth = 250;
const elementRowWidth = 200;
const companyRowWidth = 150;
const tagGroupRowWidth = 150;
const dateSpectrumRowWidth = 150;
const languageRowWidth = 100;
const taxonomiesRowWidth = 150;
const dimensionValuesRowWidth = 100;
const additionalElementsRowWidth = 100;
const isLabelRowWidth = 150;

// - Api routes 

const AvaliableContexts = "api/custom-objects/contexts/available";

/*  CustomObject - dialog Tables 
------------------------------------------------------------------------ */
export const TABLE_CONFIG = (): Record<string, ColumnProps[]> => ({
    basicList: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`,
                align: 'left'
            },
            key: "dimensionValue",
            width: 200,
        }
    ],
    dimension: [
        {
            header: {
                id: "dimension",
                label: t`Dimension`
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "typedMemberValue",
                label: t`Typed Member Value`
            },
            key: "typedMemberValue",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`
            },
            key: "consolidated",
            width: 200,
        }
    ],
    labelDimensionValues: [
        {
            header: {
                id: "language",
                label: t`Language`,
                valueFormatter: (row) => row?.language,
            },
            key: "language",
            width: 200,
        },
        {
            header: {
                id: "label",
                label: t`Label`,
                valueFormatter: (row) => row?.label,
            },
            key: "label",
            width: 200,
        }
    ],
    contextDimensionValues: [
        {
            header: {
                id: "dimensionName",
                label: t`Dimension Name`,
                valueFormatter: (row) => row?.name,
            },
            key: "dimensionName",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                valueFormatter: (row) => row?.dimensionName,
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`
            },
            key: "consolidated",
            width: 200,
        }
    ],
    tagsDimensionValues: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`
            },
            key: "dimensionValue",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`
            },
            key: "consolidated",
            width: 200,
        }
    ],
    headerDimensionValues: [
        {
            header: {
                id: "dimensionName",
                label: t`Dimension Name`
            },
            key: "dimensionName",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "typedMemberValue",
                label: t`Typed Member Value`
            },
            key: "typedMemberValue",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`
            },
            key: "consolidated",
            width: 200,
        }
    ],
    childDimensionValues: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`
            },
            key: "dimensionValue",
            width: 200,
        }
    ],
    element: [
        {
            header: {
                id: "additionalElements",
                label: t`Additional Elements`,
                valueFormatter: (row) => row?.name,
            },
            key: "additionalElements",
            width: 200,
        }
    ],
    extension: [
        {
            header: {
                id: "structure",
                label: t`Structure`
            },
            key: "structure",
            width: 200,
        }
    ],
    extensionStructure: [
        {
            header: {
                id: "structure",
                label: t`Structure`,
                valueFormatter: (row) => row?.name,
            },
            key: "structure",
            width: 200,
        }
    ],
    hierarchyElementReferences: [
        {
            header: {
                id: "element",
                label: t`Element`,
                valueFormatter: (row) => row?.name,
            },
            key: "element",
            width: 200,
        }
    ],
    onlyDimension: [
        {
            header: {
                id: "dimension",
                label: t`Dimension`
            },
            key: "dimension",
            width: 200,
        }
    ],
    settingsLabels: [
        {
            header: {
                id: "label",
                label: t`Label`,
                valueFormatter: (row) => row?.text,
            },
            key: "label",
            width: 200,
        },
        {
            header: {
                id: "language",
                label: t`Language`,
                valueFormatter: (row) => row?.lang,
            },
            key: "language",
            width: 200,
        }
    ]
});

/*  Custom Object Templates 
------------------------------------------------------------------------ */

export const tagsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "tags",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            tagGroup: {
                key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null,
            },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null, },
            isLabel: { key: "isLabel", name: t`Is Label`, fieldType: "checkbox", value: null },
            isSetting: { key: "isSetting", name: t`Is Setting`, fieldType: "checkbox", value: null },
            mergePresentation: { key: "mergePresentation", name: t`Merge Presentation`, fieldType: "checkbox", value: null },
            isFootnote: { key: "isFootnote", name: t`Is Footnote`, fieldType: "checkbox", value: null, },
            isArea: { key: "isArea", name: t`Is Area`, fieldType: "checkbox", value: null, },
            language: { key: "language", name: t`Language`, fieldType: "searchSelect", value: null, },
            context: { key: "context", name: t`Context`, fieldType: "endpoint", value: null, endpointUrl: AvaliableContexts },
        },
        tableTag: {
            navBarName: t`Table Tag`,
            isTableTag: { key: "isTableTag", name: t`Is Table Tag`, fieldType: "checkbox", value: null },
            isSetting: { key: "isSetting", name: t`Is Setting`, fieldType: "checkbox", value: null },
            strictParsing: { key: "strictParsing", name: t`Strict Parsing`, fieldType: "checkbox", value: null },
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Values`,
        },
        extensionStructureReferences: {
            navBarName: t`Extension`,
        },
        hierarchyElementReferences: {
            navBarName: t`Extension Structure`,
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
        globals: {
            customTaxonomyGroup: { key: "customTaxonomyGroup", name: t`Custom Taxonomy Group`, fieldType: "text", value: null },
        }
    }
}

export const headerTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "header",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`Label`, fieldType: "text", value: null, },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, },
            tagGroup: { key: "tagGroup", name: t`Tag Group`, fieldType: "select", value: null, },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            skipTagging: { key: "skipTagging", name: t`Skip Tagging`, fieldType: "checkbox", value: null },
            isLabelFootnote: { key: "isLabelFootnote", name: t`Is Label Footnote`, fieldType: "checkbox", value: null },
            language: { key: "language", name: t`Language`, fieldType: "searchSelect", value: null, },
            ignoreInPresentation: { key: "ignoreInPresentation", name: t`Ignore In Presentation`, fieldType: "checkbox", value: null },
        },
        overrides: {
            navBarName: t`Overrides`,
            context: { key: "context", name: t`Context`, fieldType: "searchSelect", value: null, endpointUrl: AvaliableContexts },
            currency: { key: "currency", name: t`Currency`, fieldType: "text", value: null, },
            scale: { key: "scale", name: t`Scale`, fieldType: "text", value: null, },
            valueLanguage: { key: "valueLanguage", name: t`Value Language`, fieldType: "searchSelect", value: null, },
            dateOverride: { key: "dateOverride", name: t`Date Override`, fieldType: "text", value: null, placeholder: "" },
        },
        searchHint: {
            navBarName: t`Search hint`,
            searchDirection: { key: "searchDirection", name: t`Search direction`, fieldType: "dateSpectrum", value: null, },
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Values`,
        },
        tuple: {
            navBarName: t`Tuple`,
            isTuple: { key: "isTuple", name: t`Is Tuple`, fieldType: "checkbox", value: null },
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, },
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
    };
};


export const labelsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "labels",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`Label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            elementReference: { key: "element", name: t`Element`, fieldType: "element", value: null, },
            nameElement: { key: "nameElement", name: t`Name Element`, fieldType: "text", value: null },
            tagGroup: {
                key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null,
            },
            dateSpectrum: {
                key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null,
            },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            language: { key: "language", name: t`Language`, fieldType: "languages", value: null, },
            IgnoreInPresentation: { key: "ignoreInPresentation", name: t`Ignore In Presentation`, fieldType: "checkbox", value: null },
        },
        overrides: {
            navBarName: t`Overrides`,
            context: { key: "context", name: t`Context`, fieldType: "endpoint", value: null, endpointUrl: AvaliableContexts, },
            currency: { key: "currency", name: t`Currency`, fieldType: "text", value: null, },
            scale: { key: "scale", name: t`Scale`, fieldType: "text", value: null, },
            valueLanguage: { key: "valueLanguage", name: t`Value Language`, fieldType: "languages", value: null, },
            transformation: { key: "transformation", name: t`Transformation`, fieldType: "text", value: null },
            before: { key: "before", name: t`Before`, fieldType: "text", value: null },
            merge: { key: "merge", name: t`Merge`, fieldType: "checkbox", value: null, alternative: true },
            allowHTML: { key: "allowHTML", name: t`Allow HTML`, fieldType: "checkbox", value: null, alternative: true },
            skipSpace: { key: "skipSpace", name: t`Skip Space`, fieldType: "checkbox", value: null, alternative: true },
            dateOverride: { key: "dateOverride", name: t`Date Override`, fieldType: "text", value: null },
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Value`,
        },
        additionalElementReferences: {
            navBarName: t`Additional Elements`,
        },
        taxonomies: [],
        tuple: {
            navBarName: t`Tuple`,
            isTuple: { key: "isTuple", name: t`Is Tuple`, fieldType: "checkbox", value: null },
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, },
        },
        usage: {
            navBarName: t`Usage`,
        },
        globals: {
            typedMemberValue: { key: "typedMemberValue", name: t`Override Typed Member`, fieldType: "dimension", value: null },
        }
    };
};

export const customDimensionsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "customDimensions",
        details: {
            navBarName: t`Dimension Value`,
            customDimensionName: { key: "customDimensionName", name: t`Custom Dimension Name`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null }
        },
        dimensionLabels: {
            navBarName: t`Dimension Labels`,
            data: [],
        },
        customLabels: {
            navBarName: t`Custom Labels`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customTags: {
            navBarName: t`Custom Tags`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        contexts: {},
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        }
    };
}

export const dimensionValueTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "dimensionValue",
        details: {
            navBarName: t`Dimension Value`,
            element: { key: "element", name: t`Dimension`, fieldType: "element", value: null, },
            customValue: { key: "customValue", name: t`Custom Value`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            isTypedMember: { key: "isTypedMember", name: t`Is Typed Member`, fieldType: "checkbox", value: null },
            isDefaultMember: { key: "isDefaultMember", name: t`Is Default Member`, fieldType: "checkbox", value: null },
            parentMember: { key: "parentMember", name: t`Parent Member`, fieldType: "text", value: null, disabled: true }
        },
        childDimensionValues: {
            navBarName: t`Child Dimension Values`,
        },
        valueLabels: {
            navBarName: t`Value Labels`,
        },
        customLabels: {
            navBarName: t`Custom Labels`,
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
        },
        customTags: {
            navBarName: t`Custom Tags`,
        },
        contexts: {
            navBarName: t`Contexts`,
        },
        customDimensionValues: {
            navBarName: t`Custom Dimension Values`,
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
    };
}


export const extensionElementsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "extensionElements",
        details: {
            navBarName: t`Details`,
            element: { key: "element", name: t`Element`, fieldType: "text", value: null, },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null, },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            elementDataType: { key: "elementDataType", name: t`Element Data Type`, fieldType: "select", value: null, },
            isInstant: { key: "isInstant", name: t`Is Instant`, fieldType: "checkbox", value: null },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            isCredit: { key: "isCredit", name: t`Is Credit`, fieldType: "alternateCheckbox", value: false },
            isAbstract: { key: "isAbstract", name: t`Is Abstract`, fieldType: "checkbox", value: null },
            parent: { key: "parent", name: t`Parent`, fieldType: "text", value: null }
        },
        childElements: {
            navBarName: t`Child Elements`,
        },
        labels: {
            navBarName: t`Labels`,
        },
        documentation: {
            navBarName: t`Documentation`,
        },
        customLabels: {
            navBarName: t`Custom Labels`,
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
    };
}

export const settingsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "settings",
        details: {
            navBarName: t`Details`,
            settingName: { key: "SettingName", name: t`Name`, fieldType: "endpoint", endpointUrl: "api/configuration/setting-codes", urlType: "GET", value: null, },
            required: { key: "required", name: t`Required`, fieldType: "checkbox", value: null }
        },
        customLabels: {
            navBarName: t`Labels`,
        },
        taxonomies: [],
    };
}


export const ContextSettingsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "contextSettings",
        details: {
            navBarName: t`Details`,
            context: { key: "context", name: t`Context`, fieldType: "text", value: null, endpointUrl: AvaliableContexts },
            period: { key: "period", name: t`Period`, fieldType: "endpoint", value: null, endpointUrl: "api/configuration/setting-codes", urlType: "GET", }
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Values`,
        },
        taxonomies: [],
    };
}

/*  Taxonomy Templates for dialog 
------------------------------------------------------------------------ */

export const taxonomyElementTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "taxonomyElement",
        details: {
            navBarName: t`Details`,
            label: { key: "name", name: t`Name`, fieldType: "text", value: null, disabled: true },
            nameSpace: { key: "nameSpace", name: t`Name Space`, fieldType: "text", value: null, disabled: true },
            periodType: { key: "periodType", name: t`Period Type`, fieldType: "select", value: null, disabled: true },
            taxonomyDataType: { key: "taxonomyDataType", name: t`Taxonomy Data Type`, fieldType: "text", value: null, disabled: true },
            parserDataType: { key: "parserDataType", name: t`Parser Data Type`, fieldType: "select", value: null },
            isAbstract: { key: "isAbstract", name: t`Is Abstract`, fieldType: "text", value: null, disabled: true },
            isCredit: { key: "isCredit", name: t`Is Credit`, fieldType: "checkbox", value: null, },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null, },
            rawNumber: { key: "rawNumber", name: t`Raw Number`, fieldType: "checkbox", value: null, },
        },
        validators: {
            navBarName: t`Validators`,
            dataTable: {
                data: [], // table - code - parameter - data - error message (user can add)
            }
        },
        labels: {
            navBarName: t`Labels`,
            dataTable: {
                data: [], // label - Role - Language
            }
        },
        customLabels: {
            navBarName: t`Custom Labels`,
            dataTable: {
                data: [], // label - element - company - tag group - date spectrum - language - taxonomies - dimensionValues - additionalElements (user can add)
            }
        },
        usage: {
            navBarName: t`Usage`,
        },
    };
}


/*  Custom Object Table Columns 
------------------------------------------------------------------------ */

export const ContextColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "context",
                label: t`Context`,
            },
            key: "context",
            width: 200,
        },
        {
            header: {
                id: "period",
                label: t`Period`,
            },
            key: "period",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 200,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
            },
            key: "dimensionValues",
            width: 200,
        },
    ];
}

export const Tagscolumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                optionsHeaderText: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                width: labelRowWidth
            },
            key: "label",
            width: labelRowWidth,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Groups`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Tag Group`,
                inputType: "select",
                width: tagGroupRowWidth,
                valueFormatter: (row) => {
                    return row.tagGroup || "";
                }
            },
            key: "tagGroup",
            width: tagGroupRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: companyRowWidth,
                valueFormatter: (row) => {
                    return row.company.Name || "";
                }
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Date Spectrum`,
                inputType: "select",
                width: dateSpectrumRowWidth,
                valueFormatter: (row) => {
                    return row.dateSpectrum.toString() || "";
                }
            },
            key: "dateSpectrum",
            width: dateSpectrumRowWidth,
        },
        {
            header: {
                id: "isLabel",
                label: t`Is Label`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Is Label`,
                width: isLabelRowWidth,
            },
            key: "isLabel",
            width: isLabelRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                shouldBeFiltered: false,
                optionsHeaderText: t`Select Taxonomy`,
                inputType: "select",
                width: taxonomiesRowWidth,
            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
            shouldWrapVertically: true,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
                shouldBeFiltered: false,
                width: dimensionValuesRowWidth,
            },
            key: "dimensionValues",
            width: dimensionValuesRowWidth,
            shouldWrapVertically: true,
        },
    ];
};

export const TagsAltColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                optionsHeaderText: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
            },
            key: "label",
            width: 200,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: 200,
                valueFormatter: (row) => {
                    return row.company.Name || "";
                }
            },
            key: "company",
            width: 200,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Groups`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Tag Group`,
                inputType: "select",
            },
            key: "tagGroup",
            width: 100,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Date Spectrum`,
                inputType: "select",
            },
            key: "dateSpectrum",
            width: 100,
        },
        {
            header: {
                id: "isLabel",
                label: t`Is Label`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Is Label`,
                options: ["true", "false"],
            },
            key: "isLabel",
            width: 50,
        },
    ];
};

export const HeadersColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                width: labelRowWidth,
                optionsHeaderText: t`Label`,
                valueFormatter: (row) => {
                    return row.label.toString();
                }
            },
            shouldWrapVertically: true,
            key: "label",
            width: labelRowWidth,
        },
        {
            header: {
                id: "elementReference_name",
                label: t`Element`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Element`,
                width: elementRowWidth,
                valueFormatter: (row) => {
                    return row.element.name.toString();
                }
            },
            key: "elementReference_name",
            shouldWrapVertically: true,
            width: elementRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: companyRowWidth,
                valueFormatter: (row) => {
                    return row.company.Name || "";
                }
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Group`,
                optionsHeaderText: t`Select Tag Group`,
                inputType: "select",
                width: tagGroupRowWidth,
                shouldBeFiltered: true,
                shouldWrapVertically: true,
                valueFormatter: (row) => {
                    return row.tagGroup.toString();
                }
            },
            key: "tagGroup",
            shouldWrapVertically: true,
            width: tagGroupRowWidth,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                optionsHeaderText: t`Select Date Spectrum`,
                width: dateSpectrumRowWidth,
                inputType: "select",
                shouldBeFiltered: true,
                shouldWrapVertically: true,
                valueFormatter: (row) => {
                    return row.dateSpectrum.toString();
                }
            },
            key: "dateSpectrum",
            shouldWrapVertically: true,
            width: dateSpectrumRowWidth,
        },
        {
            header: {
                id: "language",
                label: t`Language`,
                optionsHeaderText: t`Select Language`,
                shouldBeFiltered: true,
                inputType: "select",
                width: languageRowWidth,
                valueFormatter: (row) => {
                    return row.language.toString();
                }
            },
            key: "language",
            shouldWrapVertically: true,
            width: languageRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                width: taxonomiesRowWidth,
                valueFormatter: (row) => {
                    return row.taxonomies.join(", ");
                }
            },
            key: "taxonomies",
            shouldWrapVertically: true,
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "dimensionValues",
                width: dimensionValuesRowWidth,
                label: t`Dimension Values`,
                valueFormatter: (row) => {
                    return row.dimensionValues.toString();
                }
            },
            key: "dimensionValues",
            shouldWrapVertically: true,
            width: dimensionValuesRowWidth,
        },
        {
            header: {
                id: "additionalElements",
                label: t`Additional Elements`,
                width: additionalElementsRowWidth,
                valueFormatter: (row) => {
                    return row.additionalElements.toString();
                }
            },
            key: "additionalElements",
            shouldWrapVertically: true,
            width: additionalElementsRowWidth,
        }
    ];
}

export const labelsColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Label`,
                width: labelRowWidth,
            },
            shouldWrapVertically: true,
            key: "label",
            width: labelRowWidth,
        },
        {
            header: {
                id: "elementReference",
                label: t`Element`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Element`,
                width: elementRowWidth,

            },
            shouldWrapVertically: true,
            key: "element",
            width: elementRowWidth,
        },
        {
            header: {
                id: "company",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: companyRowWidth,
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Group`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Tag Group`,
                width: tagGroupRowWidth,
            },
            shouldWrapVertically: true,
            key: "tagGroup",
            width: tagGroupRowWidth,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Date Spectrum`,
                width: dateSpectrumRowWidth,
            },
            key: "dateSpectrum",
            width: dateSpectrumRowWidth,
        },
        {
            header: {
                id: "lang",
                label: t`Language`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Language`,
                width: languageRowWidth,
            },
            key: "lang",
            width: languageRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                width: taxonomiesRowWidth,

            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "dimensionValueReferences",
                label: t`Dimension Value`,
                shouldBeFiltered: false,
                inputType: "text",
                optionsHeaderText: t`Dimension Value`,
                width: dimensionValuesRowWidth,

            },
            key: "dimensionValue",
            width: dimensionValuesRowWidth,
        },
        {
            header: {
                id: "additionalElements",
                label: t`Additional Elements`,
                width: additionalElementsRowWidth,

            },
            key: "additionalElements",
            width: additionalElementsRowWidth,
        }
    ]
}

export const customDimensionColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "name",
                label: t`Dimension`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Dimension`,

            },
            key: "name",
            width: 600,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: 200,
                valueFormatter: (row) => {
                    return row.company.Name || "";
                }
            },
            key: "company",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                valueFormatter: (row) => {
                    return row.taxonomies
                }
            },
            key: "taxonomies",
            width: 200,
        },
    ]
}

export const dimensionValuesColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`dimension`,
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "value",
                label: t`Value`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Value`,
            },
            key: "value",
            width: 200,
        },
        {
            header: {
                id: "isTyped",
                label: t`Is Typed`,
            },
            key: "isTyped",
            width: 100,
        },
        {
            header: {
                id: "isDefault",
                label: t`Is Default`,
            },
            key: "isDefault",
            width: 200,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: 200,
                valueFormatter: (row) => {
                    return row.company.Name || "";
                }
            },
            key: "company",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 100,
        },
        {
            header: {
                id: "wider",
                label: t`Wider`,
            },
            key: "wider",
            width: 200,
        },
        {
            header: {
                id: "narrower",
                label: t`Narrower`,
            },
            key: "narrower",
            width: 200,
        },
    ]
}

export const extensionElementsColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "name",
                label: t`Element Name`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Element Name`,
            },
            key: "name",
            width: 200,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: 200,
                valueFormatter: (row) => {
                    return row.company.Name || "";
                }
            },
            key: "company",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 100,
        },
        {
            header: {
                id: "parentReference_name",
                label: t`Wider`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Wider`,
            },
            key: "parentReference_name",
            width: 200,
        },
        {
            header: {
                id: "narrower",
                label: t`Narrower`,
            },
            key: "narrower",
            width: 200,
        },
    ]
}

export const settingsColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "settingName",
                label: t`Setting`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Setting`,
                valueFormatter: (row) => {
                    return row ? row.SettingName || "" : "";
                }
            },
            key: "settingName",
            width: 200,
        },
        {
            header: {
                id: "required",
                label: t`Required`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Required`,
                valueFormatter: (row) => {
                    return row.required ? row.required.toString() : '';
                }
            },
            key: "required",
            width: 100,
        },
        {
            header: {
                id: "Labels",
                label: t`Labels`,
                shouldBeFiltered: false,
                optionsHeaderText: t`Labels`,
                valueFormatter: (row) => {
                    return (Array.isArray(row?.Labels)) ? (row.Labels.find((label: any) => label.Lang === "en").Text || `No English Label`) : `No Labels`;
                },
            },
            key: "Labels",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                valueFormatter: (row) => {
                    return JSON.parse(localStorage.getItem("customObjectsSelectedTaxonomies") || "[]").map((taxonomy: TaxonomyListProps) => {
                        return taxonomy.name;
                    }).join(", ");
                },
            },
            key: "taxonomies",
            width: 100,
        },
    ]
}

export const contextSettingsColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "context",
                label: t`Context`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Context`,
            },
            key: "context",
            width: 200,
        },
        {
            header: {
                id: "period",
                label: t`Period`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Period`,
            },
            key: "period",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 100,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
            },
            key: "dimensionValues",
            width: 200,
        },
    ]
}

export const globalRequestColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Label`,
            },
            key: "label",
            width: 200,
        },
        {
            header: {
                id: "entity",
                label: t`Entity`,
                shouldBeFiltered: true,
                inputType: "select",
                options: ["Entity 1", "Entity 2", "Entity 3"],
                optionsHeaderText: t`Select Entity`
            },
            key: "entity",
            width: 200,
        },
        {
            header: {
                id: "company",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
            },
            key: "company",
            width: 200,
        },
        {
            header: {
                id: "requster",
                label: t`Requester`,
                shouldBeFiltered: true,
                inputType: "select",
                options: ["Requester 1", "Requester 2", "Requester 3"],
                optionsHeaderText: t`Select Requester`
            },
            key: "requester",
            width: 200,
        },
    ]
}

export const taxonomyDiemsnionValueColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                optionsHeaderText: t`Dimension`,
                shouldBeFiltered: true,
                inputType: "text",
                width: 600,
            },
            key: "dimension",
            width: 600,
        },
        {
            header: {
                id: "value",
                label: t`Value`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Value`,
                inputType: "select",
                width: 150,
            },
            key: "value",
            width: 150,
        },
        {
            header: {
                id: "isTypedMember",
                label: t`Is Typed Member`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Is Typed Member`,
                inputType: "select",
                width: 250,
            },
            key: "isTypedMember",
            width: 250,
        },
        {
            header: {
                id: "isDefault",
                label: t`Is Default`,
                shouldBeFiltered: true,
                inputType: "select",
                width: 250,
            },
            key: "isDefault",
            width: 250,
        },
    ];
};
