import { ListItemText, Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { cDateSpectrums } from "../../api/types";
import Grid from '@mui/material/Grid2';
import { generalGet } from "../../api/general";

export const DateSpectrumLabel = ({
    label,
    value,
    onValueChange,
    disabled,
    placeholder,
    setSelectedValue,
}: {
    label?: string | undefined,
    value?: string,
    onValueChange?: (event: string) => void,
    disabled?: boolean,
    placeholder?: string,
    setSelectedValue?: (value: cDateSpectrums | null) => void,
}) => {
    const [optionsState, setOptionsState] = useState<cDateSpectrums[] | undefined>(undefined);

    useEffect(() => {
        const fetchData = async () => {
            const data = await generalGet<cDateSpectrums[]>('api/configuration/date-spectrums');
            setOptionsState(data.data as cDateSpectrums[]);
        };

        fetchData();
    }, []);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid size={4}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={6}>
                <Autocomplete
                    options={(optionsState || []).map((option) => option.dateSpectrum)}
                    getOptionLabel={(option) => option}
                    value={value as string || ""}
                    onChange={(event, newValue) => {
                        const selectedOption = (optionsState || []).find(
                            (option) => option.dateSpectrum === newValue
                        );
                        if (setSelectedValue) {
                            setSelectedValue(selectedOption ? selectedOption : null);
                        }
                        if (onValueChange) {
                            onValueChange(newValue || "");
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%" }}
                            inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            variant="standard"
                            disabled={disabled}
                        />
                    )}
                />
            </Grid>
        </Grid>
    )
}