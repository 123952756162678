import { getCustomObjectTaxonomies } from "../api/taxonomies";
import { TaxonomyListProps } from "../api/types";

export const getTaxonomiesFromLocalStorage = (): TaxonomyListProps[] => {
    const storedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");
    return storedTaxonomies ? JSON.parse(storedTaxonomies) : [];
};

export const combineTaxonomies = (defaultTaxonomies: TaxonomyListProps[], storedTaxonomies: TaxonomyListProps[]): TaxonomyListProps[] => {
    const combinedTaxonomies = defaultTaxonomies.map(defaultTaxonomy => {
        const storedTaxonomy = storedTaxonomies.find(taxonomy => taxonomy.id === defaultTaxonomy.id);
        if (storedTaxonomy) {
            return { ...defaultTaxonomy, isSelected: storedTaxonomy.isSelected };
        }
        return defaultTaxonomy;
    });

    storedTaxonomies.forEach(storedTaxonomy => {
        if (!combinedTaxonomies.find(taxonomy => taxonomy.id === storedTaxonomy.id)) {
            combinedTaxonomies.push(storedTaxonomy);
        }
    });

    return combinedTaxonomies;
};

export const getAllTaxonomies = async () => {
    const response = await getCustomObjectTaxonomies();
    const defaultTaxonomies = response.data;
    const storedTaxonomies = getTaxonomiesFromLocalStorage();
    return combineTaxonomies(defaultTaxonomies, storedTaxonomies);
};

export const handleTaxonomiesChange = (changedTaxonomies: TaxonomyListProps[]): void => {
    const storedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");
    let taxonomies: TaxonomyListProps[] = storedTaxonomies ? JSON.parse(storedTaxonomies) : [];

    const updatedTaxonomies = changedTaxonomies.reduce((acc, changedTaxonomy) => {
        const existingTaxonomyIndex = acc.findIndex(taxonomy => taxonomy.id === changedTaxonomy.id);

        if (changedTaxonomy.isSelected) {
            if (existingTaxonomyIndex === -1) {
                acc.push(changedTaxonomy);
            } else {
                acc[existingTaxonomyIndex] = changedTaxonomy;
            }
        } else {
            if (existingTaxonomyIndex !== -1) {
                acc.splice(existingTaxonomyIndex, 1);
            }
        }
        return acc;
    }, taxonomies);

    localStorage.setItem("customObjectsSelectedTaxonomies", JSON.stringify(updatedTaxonomies));
};

export const getSelectedTaxonomyIds = (): string[] | null => {
    let storedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");
    if (!storedTaxonomies || storedTaxonomies === "[]") {
        storedTaxonomies = localStorage.getItem("selectedTaxonomies");
    }

    if (!storedTaxonomies) {
        return null;
    }

    try {
        const parsedTaxonomies = JSON.parse(storedTaxonomies);
        if (!Array.isArray(parsedTaxonomies)) {
            console.error("Parsed taxonomies is not an array:", parsedTaxonomies);
            return null;
        }

        const taxonomies = parsedTaxonomies.map((t: TaxonomyListProps) => {
            if (!t.id) {
                console.warn("Taxonomy item missing id:", t);
                return null;
            }
            return t.id;
        });

        return taxonomies.filter((t: string | null) => t !== null) as string[];
    } catch (error) {
        console.error("Error parsing storedTaxonomies:", error);
        return null;
    }
}