import { t } from "@lingui/macro";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useState } from "react";
import { cTaxonomyElement, ElementReference } from "../../api/types";
import LabeledTextField from "../labeledTextField/labeledTextField";

const initialDimensionValue: cTaxonomyElement = {
    typedMemberValue: null,
    dimensionValue: {
        dimensionName: "",
        valueName: "",
        name: "",
        isCustom: false,
        taxonomyValue: {
            valueName: "",
            dimensionReference: {
                namespaceAbbreviation: "",
                name: "",
                description: "",
            },
            dimensionValueReference: {
                namespaceAbbreviation: "",
                name: "",
                description: "",
            },
        },
    },
};

export const DialogCustomDimensionValue = ({
    open,
    onClose,
    onAdd,
    type,
}: {
    open: boolean;
    onClose: () => void;
    onAdd: (dimensionValue: cTaxonomyElement) => void;
    type:
    | "basicList"
    | "extension"
    | "dimension"
    | "element"
    | "extensionStructure"
    | "onlyDimension"
    | "tagsDimensionValues"
    | "headerDimensionValues"
    | "labelDimensionValues"
    | "contextDimensionValues"
    | "childDimensionValues"
    | "settingsLabels";
}) => {
    const [newDimensionValue, setNewDimensionValue] = useState<cTaxonomyElement>(initialDimensionValue);

    const handleFieldChange = (fieldPath: string, value: ElementReference | cTaxonomyElement | string | boolean) => {
        setNewDimensionValue(
            (prev) =>
            ({
                ...prev,
                [fieldPath]: value,
            } as cTaxonomyElement)
        );
    };


    const handleAddDimensionValue = () => {
        onAdd(newDimensionValue);
        onClose();
    };

    const getDialogTitle = (): string => {
        const titles: Record<typeof type, string> = {
            dimension: t`Dimension Value`,
            element: t`Element Value`,
            extension: t`Extension Value`,
            extensionStructure: t`Extension Structure`,
            onlyDimension: t`Add Dimension`,
            tagsDimensionValues: t`Dimension Value`,
            headerDimensionValues: t`Dimension Value`,
            labelDimensionValues: t`Dimension Value`,
            contextDimensionValues: t`Dimension Value`,
            childDimensionValues: t`Select Element`,
            basicList: t`Select Element`,
            settingsLabels: t`Settings Label`,
        };
        return titles[type] || t`Value`;
    };

    const sharedFields = (
        <>
            <LabeledTextField
                label={t`Typed Member Value`}
                fieldType="text"
                value={newDimensionValue.typedMemberValue || ""}
                onValueChange={(value) => {
                    handleFieldChange("typedMemberValue", value)
                }}
            />
            <LabeledTextField
                label={t`Bind to Consolidated Value`}
                fieldType="select"
                options={["True", "False"]}
                value={newDimensionValue.onlyWhenConsolidated || ""}
                onValueChange={(value) => {
                    handleFieldChange("onlyWhenConsolidated", value)
                }
                }
            />
        </>
    );

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="lg"
                sx={{ "& .MuiDialog-paper": { width: "100%" } }}
            >
                <DialogTitle>{getDialogTitle()}</DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}>
                    {(type === "dimension" || type === "labelDimensionValues" || type === "tagsDimensionValues") && (
                        <>
                            <LabeledTextField
                                label={t`Dimension`}
                                fieldType="dimension"
                                value={newDimensionValue.dimensionValue.valueName || ""}
                                onValueChange={(value) => {
                                    setNewDimensionValue(
                                        {
                                            ...newDimensionValue,
                                            dimensionValue: value
                                        } as cTaxonomyElement
                                    );
                                }}
                            />
                            {sharedFields}
                        </>
                    )}
                    {type === "element" && (
                        <>
                            <LabeledTextField
                                label={t`Element`}
                                fieldType="element"
                                value={newDimensionValue.dimensionValue.name || ""}
                                onValueChange={(value) => handleFieldChange("dimensionValue.name", value)}
                            />
                            {sharedFields}
                        </>
                    )}
                    {(type === "headerDimensionValues" || type === "basicList" || type === "childDimensionValues") && (
                        <LabeledTextField
                            label={t`Element`}
                            fieldType="dimension"
                            value={newDimensionValue.dimensionValue.taxonomyValue.dimensionValueReference.name || ""}
                            onValueChange={(value) =>
                                handleFieldChange("dimensionValue.taxonomyValue.dimensionValueReference.name", value)
                            }
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t`Cancel`}</Button>
                    <Button onClick={handleAddDimensionValue}>{t`Add`}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
