import { t } from "@lingui/macro"
import { ListItemText, TextField } from "@mui/material"
import Grid from '@mui/material/Grid2'

export const SelectLabel = ({
    label,
    value,
    onValueChange,
    options,
    disabled,
    placeholder
}: {
    label?: string,
    value?: string,
    options: string[],
    onValueChange?: (event: string | boolean) => void,
    disabled?: boolean,
    placeholder?: string
}) => {
    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            {label && (
                <Grid size={4}>
                    <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
                </Grid>
            )}
            <Grid size={6}>
                <TextField
                    select
                    autoComplete='off'
                    sx={{ width: "100%" }}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    variant="standard"
                    placeholder={placeholder || ""}
                    value={value || ""}
                    disabled={disabled}
                    onChange={(event) => onValueChange && onValueChange(event.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value={""}>
                        {t`None`}
                    </option>
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    )
}