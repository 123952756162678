import { ListItemText, Autocomplete, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { cTagGroup } from "../../api/types";
import { generalGet } from "../../api/general";

export const TagGroupLabel = ({
    label,
    value,
    onValueChange,
    disabled,
    placeholder,
}: {
    label?: string;
    value?: string;
    onValueChange?: (event: string) => void;
    disabled?: boolean;
    placeholder?: string;
}) => {
    const [optionsState, setOptionsState] = useState<cTagGroup[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await generalGet<cTagGroup[]>("api/configuration/tag-groups");
            setOptionsState(data);
        };

        fetchData();
    }, []);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid size={4}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={6}>
                <Autocomplete
                    options={optionsState as cTagGroup[]}
                    getOptionLabel={(option) => option.name}
                    value={
                        (optionsState as cTagGroup[]).find((option) => option.name === value) || null
                    }
                    onChange={(_, newValue: cTagGroup | null) => {
                        if (onValueChange) {
                            onValueChange(newValue ? newValue?.name : "");
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            variant="standard"
                            disabled={disabled}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};
