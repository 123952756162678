import {
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { TaxonomyListProps } from "../../api/types";
import { t } from "@lingui/macro";
import { getAllTaxonomies } from "../../helpers/taxonomyHelpers";

interface SelectingTaxonomyProps {
  open: boolean;
  isDialog: boolean;
  onClose: () => void;
  onApply: (selectedTaxonomyIds: string[]) => void;
  preSelectedTaxonomies?: string[];
}

const SelectTaxonomies = ({
  open,
  isDialog,
  onClose,
  onApply,
  preSelectedTaxonomies = [],
}: SelectingTaxonomyProps) => {
  return (
    <>
      {isDialog ? (
        <Container maxWidth={false} sx={{ p: 0, m: 0 }}>
          <Dialog open={open} onClose={onClose} fullWidth>
            <Container sx={{ mt: 5, mb: 5 }}>
              <MainTaxonomySelector
                isDialog={isDialog}
                open={open}
                onClose={onClose}
                onApply={onApply}
                preSelectedTaxonomies={preSelectedTaxonomies}
              />
            </Container>
          </Dialog>
        </Container>
      ) : (
        <Container
          sx={{
            p: 0,
            m: 0,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <MainTaxonomySelector
            isDialog={isDialog}
            open={open}
            onClose={onClose}
            onApply={onApply}
            preSelectedTaxonomies={preSelectedTaxonomies}
          />
        </Container>
      )}
    </>
  );
};

const MainTaxonomySelector = ({
  isDialog,
  onApply,
  onClose,
  preSelectedTaxonomies,
}: SelectingTaxonomyProps) => {
  const [localTaxonomies, setLocalTaxonomies] = useState<TaxonomyListProps[]>([]);
  const [selectedTaxonomyIds, setSelectedTaxonomyIds] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTaxonomies = async () => {
      try {
        const allTaxonomies = await getAllTaxonomies();

        const updatedTaxonomies = allTaxonomies.map((taxonomy) => ({
          ...taxonomy,
          isSelected: preSelectedTaxonomies?.includes(taxonomy.id) || taxonomy.isSelected || false,
        }));

        setLocalTaxonomies(updatedTaxonomies);

        const initialSelectedIds = updatedTaxonomies
          .filter((taxonomy) => taxonomy.isSelected)
          .map((taxonomy) => taxonomy.id);

        setSelectedTaxonomyIds(initialSelectedIds);
      } catch (error) {
        setError(t`Failed to load taxonomies. Please try again later.`);
      }
    };

    fetchTaxonomies();
  }, [preSelectedTaxonomies]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    const updatedTaxonomies = localTaxonomies.map((taxonomy) => {
      if (taxonomy.id === value) {
        return {
          ...taxonomy,
          isSelected: checked,
        };
      }
      return taxonomy;
    });

    setLocalTaxonomies(updatedTaxonomies);

    const updatedSelectedIds = checked
      ? [...selectedTaxonomyIds, value]
      : selectedTaxonomyIds.filter((id) => id !== value);

    setSelectedTaxonomyIds(updatedSelectedIds);
    if (!isDialog) {
      onApply(updatedSelectedIds);
    }
  };

  const handleApply = () => {
    onApply(selectedTaxonomyIds);
    onClose();
  };

  return (
    <Container sx={{ m: 0, p: 0 }}>
      <Typography variant="h5">{t`Select Taxonomies`}</Typography>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <FormControl fullWidth>
          <FormGroup>
            {localTaxonomies.map(({ id, isSelected, name }) => (
              <FormControlLabel
                key={id}
                control={
                  <Checkbox
                    checked={isSelected}
                    onChange={handleChange}
                    value={id}
                    sx={{ p: isDialog ? 1 : 0.5 }}
                  />
                }
                label={name || id}
                sx={{ m: 0 }}
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
      {isDialog && !error && (
        <Container sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
          <Button variant="outlined" onClick={handleApply}>
            {t`Apply`}
          </Button>
          <Button sx={{ ml: 2 }} variant="outlined" onClick={onClose}>
            {t`Cancel`}
          </Button>
        </Container>
      )}
      {isDialog && error && (
        <Container sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
          <Button sx={{ ml: 2 }} variant="outlined" onClick={onClose}>
            {t`Close`}
          </Button>
        </Container>
      )}
    </Container>
  );
};

export default SelectTaxonomies;